(function(angular, undefined) {
  angular.module("webPartnerPortalApp.constants", [])

.constant("appConfig", {
	"assets": {
		"theme": "lumberjack",
		"cssPath": "bower_components/web-shared-assets/assets/stylesheets/themes/lumberjack/",
		"imagePath": "bower_components/web-shared-assets/assets/images/shared/themes/lumberjack/"
	},
	"app_cookie": "prosper-abp",
	"ROLE_NAME": "AccountingMgr",
	"SESSION_TIMEOUT": 20,
	"ACCESS_TOKEN_GRANT_TYPE": "client_credentials",
	"USER_TOKEN_GRANT_TYPE": "password",
	"THEME_NAME": "blue_elephant",
	"USER_SOURCE_SYSTEM": "PUBLIC_SITE"
})

;
})(angular);